<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <h4 class="page-title">Evaluating Weight</h4>
                </div>
            </div>
        </div>
        <!-- end page title -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <div class="d-flex">
                            <div class="d-flex">
                                <label class="col-form-label me-1">Program Name</label>
                                <div style="width: 150px">
                                    <v-select label="short_name" v-model="program" :options="programs" :clearable="false"
                                        @option:selected="getListbyProgram($event)">
                                    </v-select>
                                </div>
                            </div>
                            <label class="col-form-label me-1 ms-3">Level</label>
                            <div style="width: 150px">
                                <v-select label="name" v-model="level" :options="levels" :clearable="false"
                                    :reduce="(name) => name.name" @option:selected="getListbyLevel($event, this.program)">
                                </v-select>
                            </div>
                            <label class="col-form-label me-1 ms-3">Type</label>
                            <div style="width: 150px">
                                <v-select v-model="type" label="name" :options="types" :reduce="(name) => name.name"
                                    @option:selected="getListbyType($event, this.program, this.level)" :clearable="false">
                                </v-select>
                            </div>
                            <div @click="refreshData" class="icon-css">
                                <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                            </div>
                        </div>

                        <div class="d-flex justify-content-end mb-4">
                            <router-link :to="{ name: 'evaluating-weight-create' }">
                                <button v-show="!loading" type="button"
                                    class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                                    v-if="hasPermissions(['module-group-create'])">
                                    <i class="mdi mdi-plus-circle"></i> Add Record
                                </button>
                            </router-link>
                        </div>
                        <place-holder v-if="loading"></place-holder>
                        <div class="table-responsive" v-if="!loading">
                            <table class="table table-striped dt-responsive w-100 mb-3"
                                id="evaluating-weight-list-datatable" v-show="!loading">
                                <thead v-if="!loading">
                                    <tr>
                                        <th>No.</th>
                                        <th>Criteria</th>
                                        <th>Program Name</th>
                                        <th>Level</th>
                                        <th>Type</th>
                                        <th>Weight</th>
                                        <th v-if="hasPermissions(['module-group-edit'])">Action</th>
                                    </tr>
                                </thead>
                                <tbody v-if="!loading">
                                    <tr v-for="(item, index) in criteria" :key="index">
                                        <td>{{ ++index }}</td>
                                        <td>{{ item.evaluating_criteria.name }}</td>
                                        <td>{{ item.program_short_name }}</td>
                                        <td>{{ item.level }}</td>
                                        <td>{{ item.type }}</td>
                                        <td>{{ item.weight }}</td>
                                        <td v-if="hasPermissions(['module-group-edit'])">
                                            <span>
                                                <router-link class="action-icon" :to="{
                                                    name: 'evaluating-weight-update',
                                                    params: { id: item.id },
                                                }">
                                                    <i class="mdi mdi-square-edit-outline"></i>
                                                </router-link>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
//Datatable Orientation Criteria
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";

//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
    mixins: [userHasPermissions],
    setup() {
        const toast = useToast();
        return { toast };
    },
    components: {
        vSelect,
    },
    data() {
        return {
            programs: [],
            levels: [],
            types: [
                {
                    id: 1,
                    name: "Orientation"
                },
                {
                    id: 2,
                    name: "In-Class"
                },
                {
                    id: 3,
                    name: "OJT"
                },
            ],
            program: "",
            level: "",
            type: "",
            criteria: [],
            loading: false,
            baseUrl: process.env.VUE_APP_BASE_URL,
            baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
        };
    },
    methods: {
        async getPrograms() {
            this.loading = true;
            await axios
                .get(`${this.baseUrlHRIS}api/programs`)
                .then((response) => {
                    this.programs = response.data.data;
                    this.loading = false;
                })
                .catch(() => {
                    this.toast.error("Not Found Program!");
                });
        },
        async getAllLevels() {
            this.loading = true;
            await axios.get(`${this.baseUrl}admin/v1/levels`).then((response) => {
                this.levels = response.data.data;
                this.loading = false;
            });
        },
        async getAllCriteria() {
            this.loading = true;
            this.$Progress.start();
            await axios
                .get(`${this.baseUrl}admin/v1/evaluating-weight`)
                .then((response) => {
                    this.criteria = response.data.data;
                    this.loading = false;
                    this.$Progress.finish();
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    this.toast.error("Something Went Wrong!");
                });
            $("#evaluating-weight-list-datatable").DataTable();
        },
        getListbyProgram(value) {
            this.level = "";
            this.type = "";
            this.clearFilter();
            $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
                console.log(settings, dataIndex);
                let programFilter = value.short_name;
                let programStage = data[2];
                console.log(programFilter, programStage);
                if (programFilter == programStage) {
                    return true;
                }
                return false;
            });
            $("#evaluating-weight-list-datatable").DataTable().draw();
        },
        getListbyLevel(value, program) {
            this.type = "";
            this.clearFilter();
            $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
                console.log(settings, this.program, dataIndex);
                let programFilter = program.short_name;
                let programStage = data[2];
                let levelFilter = value.name;
                let levelStage = data[3];
                if ((programFilter == programStage || !programFilter ) && levelFilter == levelStage) {
                    return true;
                }
                return false;
            });
            $("#evaluating-weight-list-datatable").DataTable().draw();
        },
        getListbyType(value, program, level) {
            this.clearFilter();
            $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
                console.log(settings, dataIndex);
                let programFilter = program.short_name;
                let programStage = data[2];
                let levelFilter = level;
                let levelStage = data[3];
                let typeFilter = value.name;
                let typeStage = data[4];
                if ((programFilter == programStage || !programFilter) && (levelFilter == levelStage || !levelFilter) && typeFilter == typeStage) {
                    return true;
                }
                return false;
            });
            $("#evaluating-weight-list-datatable").DataTable().draw();
        },
        refreshData() {
            this.program = "";
            this.level = "";
            this.type = "";
            this.criteria = [];
            this.clearFilter();
            this.getPrograms();
            this.getAllLevels();
            this.getAllCriteria();
        },
        clearFilter() {
            $.fn.dataTable.ext.search.pop();
            $("#evaluating-weight-list-datatable").DataTable().draw();
        },
    },
    created() {
        this.clearFilter();
        this.getPrograms();
        this.getAllLevels();
        this.getAllCriteria();
    },
};
</script>

<style>
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #4a81d4;
    border-color: #4a81d4;
}
</style>
  